import React, { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { StethoscopeIcon } from '../../components/icons/StethoscopeIcon'
import { MedicalHistoryIcon } from '../../components/icons/MedicalHistoryIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'

import heroImg from '../../assets/images/home-hero-mobile.png'

import logoImgMobile from '../../assets/images/Sage_EB_Secondary_Logo_Black_RGB.png'
import heroImgDesktop from '../../assets/images/home-hero-desktop.jpg'
import medsolLogoImg from '../../assets/images/HealthHeroLogo.svg'

export const HomePage = memo(function HomePage() {
  useEffect(() => {
    //@ts-ignore
    window.gtag('config', 'UA-78156948-68')
  }, [])
  return (
    <div className="pattern-bg page home-page">
      <div className="header">
        <img src={logoImgMobile} alt="Sage Employee Benefits" />
      </div>
      <div className="hero-unit">
        <img
          className="hero-banner-mobile unselectable hide-tablet"
          src={heroImg}
          alt=""
        />
        <img className="hero-banner-desktop" src={heroImgDesktop} alt="" />
        <div className="hero-logo-container">
          <img
            src={logoImgMobile}
            className="hero-logo"
            alt="Sage Employee Benefits"
          />
          <div className="hero-text">On Demand GP</div>
          <div className="hero-description hide-mobile">
            Our 24/7 private GP helpline enables <br />
            you to speak to a GP at a time <br />
            that suits you, wherever you are.
          </div>
        </div>
      </div>
      <div className="bottom-container">
        <div className="button-tile-container">
          <div className="button-tile button-tile-primary">
            <div className="button-container">
              <StethoscopeIcon className="thethoscope-icon button-icon" />
              <Link to={ScreenRoutes.GP_CONSULT}>
                <button className="btn">Speak to a GP</button>
              </Link>
            </div>
          </div>
          <div className="button-tile button-tile-secondary">
            <div className="button-container">
              <svg
                width="41px"
                height="41px"
                viewBox="0 0 42 41"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="button-icon"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    transform="translate(0.416667, 0.000000)"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                  >
                    <g>
                      <circle cx="20.5" cy="20.5" r="19.5"></circle>
                      <circle cx="20.5" cy="9.5" r="2.5"></circle>
                      <rect x="18" y="16" width="5" height="19" rx="2.5"></rect>
                    </g>
                  </g>
                </g>
              </svg>
              <Link to={ScreenRoutes.HOW_IT_WORKS}>
                <button className="btn">About</button>
              </Link>
            </div>
          </div>
          <div className="button-tile button-tile-secondary">
            <div className="button-container">
              <MedicalHistoryIcon
                color="#fff"
                className="medical-history-icon button-icon"
              />
              <Link to={ScreenRoutes.HEALTH_INFORMATION}>
                <button className="btn">Health Info</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="appointment-info">
          <div className="consultation-info">
            <div className="consultation-info-icon-container display-flex flex-center">
              <PhoneConsultationIcon />
            </div>
            <div className="consultation-info-description">
              <div className="consultation-text">
                To book an appointment with a GP 24/7 call:
              </div>
              <div className="consultation-phone">0345 319 0567</div>
              <Link
                className="online-booking-form-link"
                to={ScreenRoutes.REQUEST_APPOINTMENT}
              >
                or use our online booking form &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="footer display-flex flex-center">
          <img
            className="medsol-logo"
            src={medsolLogoImg}
            alt="HealthHero Logo"
          />
          <div className="footer-text">
            On Demand GP is a service provided by HealthHero Solutions Limited,
            specialists in 24/7 Private GP services.
          </div>
        </div>
      </div>
    </div>
  )
})
