import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const MedicalHistoryIcon = memo<IconProps & { color: string }>(
  function HeartbeatIcon(props) {
    return (
      <svg
        className={props.className}
        viewBox="0 0 41 38"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-256.000000, -493.000000)"
            stroke={props.color}
          >
            <g transform="translate(258.000000, 495.000000)">
              <g
                transform="translate(14.500470, 13.458333)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              >
                <polyline points="2.18373919 0 4.96493019 0 4.96493019 11.3333333 0.695699425 11.3333333"></polyline>
                <line
                  x1="9.23416096"
                  y1="11.3333333"
                  x2="4.96493019"
                  y2="11.3333333"
                ></line>
              </g>
              <ellipse
                fill="#fff"
                cx="19.1254697"
                cy="8.14583333"
                rx="1.27884615"
                ry="1.27083333"
              ></ellipse>
              <path
                d="M19.4812389,0 C28.9125631,0 36.558162,7.61115925 36.558162,17 C36.558162,26.3888407 28.9125631,34 19.4812389,34 C15.9059996,34 12.5873737,32.9062559 9.84457814,31.0365466 L0.231631087,33.182654 L4.81633838,25.7156243 C3.28463886,23.167044 2.40431583,20.1858641 2.40431583,17 C2.40431583,7.61115925 10.0499147,0 19.4812389,0 Z"
                strokeWidth="3"
                strokeLinejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    )
  },
)
