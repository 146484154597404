import React, { memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { MessageIcon } from '../../components/icons/MessageIcon'
import { TextInput } from '../../components/form/TextInput'
import { PageHeader } from '../../components/app/PageHeader'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { useForm, NestDataObject, FieldError } from 'react-hook-form'
import { messageDr } from '../../api'
import { isValidEmailStrict } from '../../util/validation/email'
import config from '../../config'
import { loadReCaptcha } from '../../util/recaptcha'
import { TextArea } from '../../components/form/TextArea'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number',
  email: 'Enter valid email address',
  postcode: 'Enter valid postcode',
  required: 'Required',
}

function getError<T>(errors: NestDataObject<T>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const MessageDrPage = memo(function MessageDrPage() {
  const { register, handleSubmit, formState, errors } = useForm()

  const [submitErrorMesasge, setSubmitErrorMessage] = useState('')

  const [img, setImg] = useState<any>(null)

  const history = useHistory()

  useEffect(() => loadReCaptcha(config.recaptcha.siteKey), [])

  const onSubmit = async (message: any) => {
    setSubmitErrorMessage('')
    try {
      const recaptchaToken = await grecaptcha.execute(
        config.recaptcha.siteKey,
        { action: 'message_dr' },
      )

      const response = await messageDr({
        ...message,
        recaptchaToken,
        appId: config.appId,
      })

      if (response.success) {
        history.push(ScreenRoutes.MESSAGE_SENT)
        return
      }
      setSubmitErrorMessage(
        response.message || 'Message submit failed. Please try again later',
      )
    } catch {
      setSubmitErrorMessage('Message submit failed. Please try again later')
    }
  }

  const checkKeyDown = (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
    >,
  ) => {
    const keyCode = e.keyCode ? e.keyCode : e.which

    //@ts-ignore
    if (keyCode === 13 && e.target.tagName?.toLowerCase() !== 'textarea') {
      e.preventDefault()
    }
  }

  return (
    <div className="page message-dr-page">
      <PageHeader
        title="Message Dr."
        icon={<MessageIcon className="button-icon" />}
      />

      <div className="content paragraph message-dr-text">
        If you have a general healthcare query, you can message a doctor using
        this simple service. The doctor will respond to your email address the
        next working day, at the latest.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tbody>
            <tr>
              <td className="text-left">Monday - Sunday</td>
              <td className="text-right">08:00-22:00</td>
            </tr>
          </tbody>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className="form"
      >
        <TextInput
          name="policyNumber"
          label="Scheme code"
          inputRef={register}
        />
        <TextInput
          error={getError(errors, 'fullName')}
          inputRef={register({ required: true })}
          name="fullName"
          label="Full Name"
        />
        <TextInput
          error={getError(errors, 'dob')}
          inputRef={register({ required: true })}
          name="dob"
          type="date"
          label="D.O.B"
        />

        <div className="form-control">
          <label>Gender</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="gender"
                value="Male"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Male</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="gender"
                value="Female"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Female</span>
            </label>
          </div>
          {errors.gender ? (
            <div className="error-message">{getError(errors, 'gender')}</div>
          ) : null}
        </div>
        <TextInput
          error={getError(errors, 'email')}
          inputRef={register({
            validate: {
              email: isValidEmailStrict,
            },
          })}
          name="email"
          label="Email"
        />
        <TextArea
          rows={5}
          inputRef={register}
          name="question"
          label="Type your question below"
        />
        <div className="form-control">
          <label className="btn input-file-label">
            <span>Attach an image</span>
            <input
              name="image"
              ref={register}
              type="file"
              onChange={e =>
                setImg(
                  e.target.files && e.target.files[0]
                    ? URL.createObjectURL(e.target.files[0])
                    : null,
                )
              }
              accept="image/*"
              className="input-file"
            />
          </label>
          <img alt="" style={{ height: '60px', marginTop: '10px' }} src={img} />
        </div>
        <div className="form-control">
          <button
            disabled={formState.isSubmitting}
            type="submit"
            className="btn btn-request-consultation"
          >
            {formState.isSubmitting ? 'Please wait....' : 'Send to Doctor'}
          </button>
        </div>
        <div className="form-control">
          <div className="recaptcha-policy content paragraph">
            In addition to HealthHero{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              Terms and Conditions
            </a>{' '}
            , this site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </div>
        </div>
        {!!submitErrorMesasge ? (
          <div className="error-message">{submitErrorMesasge}</div>
        ) : null}
      </form>
    </div>
  )
})
